import Session from "./session";
import CbaLogo from "assets/img/brand/cba/justicia-logo.png";
import SLLogo from "assets/img/brand/sl/justicia-logo-san-luis.png";
import SELogo from "assets/img/brand/subastas_electronicas/se-logo.png";
import { site, sources } from "./sources";

export const PhoneAreaCodes = [
  11, 220, 221, 223, 230, 236, 237, 249, 260, 261, 263, 264, 266, 280, 291, 294,
  297, 298, 299, 336, 341, 342, 343, 345, 348, 351, 353, 358, 362, 364, 370,
  376, 379, 380, 381, 383, 385, 387, 388, 2202, 2221, 2223, 2224, 2225, 2226,
  2227, 2229, 2241, 2242, 2243, 2244, 2245, 2246, 2252, 2254, 2255, 2257, 2261,
  2262, 2264, 2265, 2266, 2267, 2268, 2271, 2272, 2273, 2274, 2281, 2283, 2284,
  2285, 2286, 2291, 2292, 2296, 2297, 2302, 2314, 2316, 2317, 2320, 2323, 2324,
  2325, 2326, 2331, 2333, 2334, 2337, 2342, 2343, 2346, 2352, 2353, 2354, 2355,
  2356, 2357, 2358, 2392, 2393, 2394, 2395, 2396, 2473, 2474, 2475, 2477, 2478,
  2622, 2624, 2625, 2626, 2646, 2647, 2648, 2651, 2652, 2655, 2656, 2657, 2658,
  2901, 2902, 2903, 2920, 2921, 2922, 2923, 2924, 2925, 2926, 2927, 2928, 2929,
  2931, 2932, 2933, 2934, 2935, 2936, 2940, 2942, 2945, 2946, 2948, 2952, 2953,
  2954, 2962, 2963, 2964, 2966, 2972, 2982, 2983, 3327, 3329, 3382, 3385, 3387,
  3388, 3400, 3401, 3402, 3404, 3405, 3406, 3407, 3408, 3409, 3435, 3436, 3437,
  3438, 3442, 3444, 3445, 3446, 3447, 3454, 3455, 3456, 3458, 3460, 3462, 3463,
  3464, 3465, 3466, 3467, 3468, 3469, 3471, 3472, 3476, 3482, 3483, 3487, 3489,
  3491, 3492, 3493, 3496, 3497, 3498, 3521, 3522, 3524, 3525, 3532, 3533, 3537,
  3541, 3542, 3543, 3544, 3546, 3547, 3548, 3549, 3562, 3563, 3564, 3571, 3572,
  3573, 3574, 3575, 3576, 3582, 3583, 3584, 3585, 3711, 3715, 3716, 3718, 3721,
  3725, 3731, 3734, 3735, 3741, 3743, 3751, 3754, 3755, 3756, 3757, 3758, 3772,
  3773, 3774, 3775, 3777, 3781, 3782, 3786, 3821, 3825, 3826, 3827, 3832, 3835,
  3837, 3838, 3841, 3843, 3844, 3845, 3846, 3854, 3855, 3856, 3857, 3858, 3861,
  3862, 3863, 3865, 3867, 3868, 3869, 3873, 3876, 3877, 3878, 3885, 3886, 3889,
  3888, 3891, 3892, 3894,
];

export function apiRequestHeaders(token = null, no_cache = false) {
  var headers = new Headers();
  // if (token === null) {
  //     token = getCookie(`${site}_token`);
  // }
  headers.append("Content-Type", "application/json; charset=utf-8");
  //headers.append("X-Custom-Header", "ProcessThisImmediately");
  if (token && token !== "") {
    headers.append("Authorization", `token ${token}`);
  }
  if (no_cache) {
    headers.append("pragma", "no-cache");
    headers.append("cache-control", "no-cache");
  }
  return headers;
}

export function apiRequestGet(url) {
  var payHeaders = new Headers();

  //var token = getCookie('token');
  //payHeaders.append("Content-Type", "application/json");
  //payHeaders.append("X-Custom-Header", "ProcessThisImmediately");
  //payHeaders.append("Authorization", `Token ${token}`);

  var myInit = {
    method: "GET",
    headers: payHeaders,
    mode: "cors",
    cache: "default",
  };

  var myRequest = new Request(url, myInit);
  return myRequest;
}

export function apiRequestPost(url, payload) {
  var payHeaders = new Headers();
  var token = getCookie("token");
  payHeaders.append("Content-Type", "application/json");
  payHeaders.append("X-Custom-Header", "ProcessThisImmediately");
  payHeaders.append("Authorization", `Token ${token}`);

  var myInit = {
    method: "POST",
    headers: payHeaders,
    mode: "cors",
    cache: "default",
    body: JSON.stringify(payload),
  };

  var myRequest = new Request(url, myInit);
  return myRequest;
}

export function apiRequestPatch(url, payload) {
  var payHeaders = new Headers();
  var token = getCookie("token");
  payHeaders.append("Content-Type", "application/json");
  payHeaders.append("X-Custom-Header", "ProcessThisImmediately");
  payHeaders.append("Authorization", `Token ${token}`);

  var myInit = {
    method: "PATCH",
    headers: payHeaders,
    mode: "cors",
    cache: "default",
    body: JSON.stringify(payload),
  };

  var myRequest = new Request(url, myInit);
  return myRequest;
}

export function as_currency(number, code = null) {
  var options = {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };
  if (code !== null) {
    options.currency = code;
  }
  var formatter = Intl.NumberFormat("es-AR", options);
  return formatter.format(number);
}

export function as_datetime(date, options) {
  var res = "";
  if (!options) {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    };
  }

  if (date != null) {
    if (typeof date === "string" || date instanceof String) {
      date = new Date(date);
    }
    try {
      res = date.toLocaleString("es-AR", options);
    } catch (error) {
      console.log("Hubo un error al mostrar la hora");
      res = date.toLocaleString("es-AR", options);
    }
  }
  return res;
}

export function get(object, key, default_value) {
  /*Pyhton-like get function for dictionaries*/
  var result = typeof object !== "undefined" ? object[key] : default_value;
  return typeof result !== "undefined" ? result : default_value;
}

export function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

export function setCookie(ckey, cvalue, exdays, domain = false) {
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = `expires=${d.toUTCString()}`;
  //var cookie_string = `${ckey}=${cvalue};${expires}${get_entity() === 'local' ? '' : ';secure'};path=/;samesite=strict`;
  var cookie_string = `${ckey}=${cvalue};${expires};path=/;samesite=strict`;
  if (domain) {
    cookie_string += `domain=${domain}`;
  }
  document.cookie = cookie_string;
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function deleteAllCookies() {
  // Doesnt work exactly as expected
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
}

export function isIPaddress(ipaddress) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  ) {
    return true;
  }
  // alert("You have entered an invalid IP address!")
  return false;
}

export function makeSecure(url) {
  var skip = ["http://51.210.38.96", "http://51.161.80.7", "http://localhost"];
  var includes = false;
  if (url != null) {
    for (var index in skip) {
      includes = includes || url.includes(skip[index]);
    }
    var partial_url = url.replace("http://", "").split("/")[0];
    if (!includes && !isIPaddress(partial_url)) {
      url = url.replace("http", "https");
    }
  }
  return url;
}

export function trad(str) {
  return str;
}

export function stringToDate(_date, _format, _delimiter) {
  var formatLowerCase = _format.toLowerCase();
  var formatItems = formatLowerCase.split(_delimiter);
  var dateItems = _date.split(_delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
}

export function reverse(
  name,
  source = false,
  args = [],
  kwargs = {},
  sources = {}
) {
  var urls = {
    auction: `/api/auction/`,
    recomendation_search:'api/recomendacion/',
    good_search: `/api/good_search/`,
    category: "/api/category/",
    good: "/api/good/",
    public_good: "/api/public_good/",
    public_question: "/api/public_question/",
    public_offer: "/api/public_offer/",
    good_view: `/product/`,
    login: `/login/`,
    first_login:'/first_login/',
    second_factor:`/api/second_factor/`,
    logout: `/logout/`,
    old_login: `/old_login/`,
    obtain_token: `/api/token_auth/`,
    make_offer: `/api/create_offer/`,
    main: "/",
    user_panel: "/panel_usuario/",
    user_data: "/panel_usuario/datos_usuario/",
    auctioneer_panel: "/panel_martillero/",
    court_panel: "/panel_tribunal/",
    admin_panel: "/panel_admin/",
    treasury_panel: "/panel_tesoreria/",
    offer: "/bien/ofertar/",
    question: `/api/question/`,
    cuit: `/api/cuit/`,
    norm_user_post: `/user_norm/`,
    registro_fisica: `/registro/fisica/`,
    registro_juridica: `/registro/juridica/`,
    contacto: `/contacto/`,
    auction_room: "/api/auction_room/",
    pass_user: "/panel_usuario/pass_usuario/",
    create_query: "/api/create_query/",
    wanna_auction: "/api/wanna_auction/",
    public_decree: "/api/public_decree/",
    stream: "/api/stream/",
    change_password: "/cambiar_contrasena/",
    copy_user: "/api/copy_user/",
    recover_password: "/api/recover_password/",
    check_username: "/api/username/",
    get_provinces: "/api/provinces/",
    get_localities: "/api/localities/",
    register_person: "/api/register_person/",
    register_enterprise: "/api/register_enterprise/",
    notification: "/api/notification/",
    terms: "/reglamento/",
    change_pass: "/api/user_pwd/",
    tax: "/api/tax/",
    auctioneer: "/api/auctioneer/",
    user: "/api/user/",
    crear_pago: "/panel_usuario/pago/",
    validation: "/api/validationie/",
    unamereport: "/api/unamereport/",
    sync_user: "/panel_usuario/user_sync/",
    deposit: "/api/deposit/",
    deposito_usuario: "/panel_usuario/deposito/",
    deposito_admin: "/panel_admin/depositos/",
    exemption: `/api/exemption/`,
    ads: `/api/custom_message/`,
    validationse: `/api/validationse/`,
    validacionse: `/validacion/`,
    sms_val: `/sms/`,
    sms: `/api/sms/`,
    mi_sala: `/auction_room/`,
    mobile_validation: `/api/mobile_validation/`,
    como_pagar: `/comopagar/`,
  };
  if (source == 'privadas'){
    urls['good_search'] = `/uapi/good_search/`
  }
  var url = urls[name];
  if (args.length > 0) {
    for (var i in args) {
      url += `${args[i]}/`;
    }
  }
  if (Object.keys(kwargs).length > 0) {
    url += "?";
    for (var key in kwargs) {
      url = url + key + "=" + kwargs[key] + "&";
    }
    url = url.slice(0, -1);
  }
  if (Object.keys(sources).length === 0) {
    sources = {
      cba: {
        url: "https://subastas.justiciacordoba.gob.ar",
        logo: CbaLogo,
        ws_path: "wss://subastas.justiciacordoba.gob.ar/wss",
        name: "Subastas Judiciales Córdoba",
      },
      sl: {
        url: "https://subastas.justiciasanluis.gov.ar",
        logo: SLLogo,
        ws_path: "wss://subastas.justiciasanluis.gov.ar/wss",
        name: "Subastas Judiciales San Luis",
      },
      privadas: {
        url: "https://subastaselectronicas.com.ar",
        logo: SELogo,
        ws_path: "wss://subastaselectronicas.com.ar/wss",
        name: "Subastas Electrónicas",
      },
      // local: {
      //   url: window.location.href.split(':3000')[0],
      //   logo: SELogo,
      //   ws_path: 'ws://localhost/wss',
      //   name: 'Localhost',
      // },
    };
  }
  var res = url;
  if (source) {
    res = sources[source].url + url;
  }
  return res;
}

export function containsObject(obj, list) {
  var i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }

  return false;
}

export function slugify(text) {
  return text
    .toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");
}

export function get_entity() {
  var locations = {
    "subastas.justiciacordoba.gov.ar": "cba",
    "subasta.justiciacordoba.gov.ar": "cba",
    "subastas.justiciacordoba.gob.ar": "cba",
    "subasta.justiciacordoba.gob.ar": "cba",
    "subastas.justiciasanluis.gov.ar": "sl",
    "subasta.justiciasanluis.gov.ar": "sl",
    "subastaselectronicas.com.ar": "se",
    "subasta-electronica.com.ar": "se",
    "subastaelectronica.com.ar": "se",
    "esubasta.ar": "se",
    "esubasta.com.ar": "se",
    "esubastar.com.ar": "se",
    "www.esubastar.com.ar": "se",
    "www.esubasta.com.ar": "se",
    "www.esubasta.ar": "se",
    "subastas.jusentrerios.gov.ar": "er",
    "subasta.jusentrerios.gov.ar": "er",
    "subastas.jusentrerios.gob.ar": "er",
    "subasta.jusentrerios.gob.ar": "er",
    localhost: "local",
  };
  return locations[window.location.href.split("/")[2]];
}

export function makeOffer(gid, sources, source, toast_container) {
  var args = [];
  var kwargs = {};
  var payload = {
    good: parseInt(gid),
  };
  var url = reverse("make_offer", source, args, kwargs, sources);
  const requestOptions = {
    method: "POST",
    headers: apiRequestHeaders(Session.getToken(source)),
    body: JSON.stringify(payload),
    mode: "cors",
  };
  if (Session.getUsername(source)) {
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          var error = get(result, "error", false);
          var detail = get(result, "detail", false);
          if (!error && detail) {
            error = detail;
          }
          if (toast_container) {
            if (error) {
              toast_container.error(error, "Oferta rechazada", {
                closeButton: true,
              });
              var cond1 =
                error === "Ud. no completó el pago del depósito en garantía.";
              var cond2 = window.location.pathname.includes("auction_room");
              if (cond1 && cond2) {
                setTimeout(() => {
                  window.location = reverse(
                    "good_view",
                    source,
                    [source, gid],
                    {},
                    sources
                  );
                }, 4000);
              }
            } else {
              toast_container.success("Oferta realizada con éxito", "Oferta", {
                closeButton: true,
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  } else {
    if (toast_container) {
      var msg = `No tienes la sesión iniciada con un usuario de ${sources[source].name}`;
      toast_container.error(msg, "Oferta rechazada", { closeButton: true });
    }
  }
}

export function getParams() {
  var params = window.location.search.replace("?", "");
  params = params.split("&");
  var res = {};
  for (var index in params) {
    let key, value;
    [key, value] = params[index].split("=");
    res[key] = value;
  }
  return res;
}

// AuctionRoom Management (New file??)
export function getRoom(entity, promises, fetch_goods = false) {
  var args = [Session.getUsername(entity)];
  var url = reverse("auction_room", entity, args, {}, this.props.sources);
  if (fetch_goods) {
    url = reverse(
      "auction_room",
      entity,
      args,
      { goods: true },
      this.props.sources
    );
  }
  const requestOptions = {
    method: "GET",
    headers: apiRequestHeaders(Session.getToken(entity)),
  };
  if (Session.getUsername(entity)) {
    promises.push(
      fetch(url, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            window.location = reverse("logout");
          }
          return res.json();
        })
        .then(
          (result) => {
            if (result.count === 1) {
              var state = this.state;
              state.rooms[entity] = result.results[0].goods;
              if (fetch_goods) {
                for (var index in result.results[0].data) {
                  var good = result.results[0].data[index];
                  state.goods[entity][good.id] = good;
                  // this.openWSocket(good.id, entity);
                }
              }
              this.setState(state);
            }
          },
          (error) => {
            console.log(error);
          }
        )
    );
  }
}

export function getRooms(callbacks, fetch_goods = false) {
  var promises = [];
  for (var entity in this.props.sources) {
    this.getRoom(entity, promises, fetch_goods);
  }
  Promise.allSettled(promises).then(() => {
    if (callbacks) {
      for (var index in callbacks) {
        var callback = callbacks[index];
        callback();
      }
    }
  });
}

export function syncRoom(entity, promises) {
  var args = [Session.getUsername(entity)];
  var kwargs = {};
  var url = reverse("auction_room", entity, args, kwargs, this.props.sources);
  var payload = { goods: this.state.rooms[entity] };
  const requestOptions = {
    method: "PUT",
    headers: apiRequestHeaders(Session.getToken(entity)),
    mode: "cors",
    body: JSON.stringify(payload),
  };
  promises.push(
    fetch(url, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result, entity);
        },
        (error) => {
          this.setState({ error: error });
        }
      )
  );
}

export function syncRooms() {
  var promises = [];
  for (var entity in this.props.sources) {
    if (this.state.rooms[entity]) {
      this.syncRoom(entity, promises);
    }
  }
  Promise.allSettled(promises).then(() => {
    //this.goodsCount();
  });
}

export function addToRoom(id, entity) {
  var rooms = this.state.rooms;
  if (rooms[entity] && rooms[entity].indexOf(id) === -1) {
    rooms[entity].push(id);
    this.setState({
      rooms: rooms,
    });
    this.syncRoom(entity, []);
  }
}

export function removeFromRoom(id, entity) {
  var rooms = this.state.rooms;
  var entity_room = rooms[entity];
  if (entity_room) {
    var room_index = -1;
    room_index = entity_room.indexOf(id);
    if (room_index !== -1) {
      entity_room.splice(room_index, 1);
    }
    this.setState({
      rooms: rooms,
    });
    this.syncRoom(entity, []);
  }
}

export function calcNextCosts(good) {
  var res = {
    taxes: {},
  };
  var value = good.next_offer;
  if (new Date() > new Date(good.end_date)) {
    value = good.price;
  }
  res.value = value;
  var total = value;
  // if (good.sellos !== 0) {
  //   var sellos_amount = (good.sellos * value) / 100;
  //   total += sellos_amount;
  //   res.sellos_amount = sellos_amount;
  // }
  if (good.iva !== 0) {
    var iva_amount = (good.iva * value) / 100;
    total += iva_amount;
    res.iva_amount = iva_amount;
  }
  for (var i in good.taxes) {
    var tax = good.taxes[i];
    if (tax.monto > 0 && tax.origen !== "VN") {
      var tax_amount =
        tax.tipo === "PO" ? (tax.monto * value) / 100 : tax.monto;
      res.taxes[tax.nombre] = tax_amount;
      total += tax_amount;
    }
  }
  res.total = total;
  return res;
}

export function checkAreaCode(code) {
  code = parseInt(code);
  return PhoneAreaCodes.find((n) => n === code) !== undefined;
}

export const checkPhone = (number) => {
  return number !== null && number.length > 5 && number.length < 9;
};

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export function removeMinutes(date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
}

export function minDate(...dates) {
  return new Date(Math.min(...dates));
}

export function maxDate(...dates) {
  return new Date(Math.max(...dates));
}

export function validateEmail(mail) {
  if (/^[\w-\.\-]+@([\w-]+\.)+[\w-]{2,4}$/.test(mail)) {
    return true;
  }
  return false;
}

export function isMobile() {
  var is_android = navigator.userAgent.toLowerCase().indexOf("android") > -1;
  var is_iphone = navigator.userAgent.toLowerCase().indexOf("iphone") > -1;
  var res = navigator.userAgentData
    ? navigator.userAgentData.mobile
    : is_iphone || is_android;
  return res;
}

/**
 * redirect by role
 * if role don't has permisions => destinyRedirect
 */
let redirectRole = (role, destinyRedirect, content) => {
  let session = Session.getSession();

  if (session[site].role !== role) {
    setTimeout(() => {
      window.location = destinyRedirect;
    }, 1000);
    return;
  }
  return content;
};
export { redirectRole };

const ping = {
  ping: function (ip, callback) {
    if (!this.inUse) {
      this.status = "unchecked";
      this.inUse = true;
      this.callback = callback;
      this.ip = ip;
      var _that = this;
      this.img = new Image();
      this.img.onload = function () {
        _that.inUse = false;
        _that.callback("responded");
      };
      this.img.onerror = function (e) {
        if (_that.inUse) {
          _that.inUse = false;
          _that.callback("responded_error", e);
        }
      };
      this.start = new Date().getTime();
      this.img.src = ip;
      this.timer = setTimeout(function () {
        if (_that.inUse) {
          _that.inUse = false;
          _that.callback("timeout");
        }
      }, 1500);
    }
  },
  get: function (entity, ip, callback) {
    const requestOptions = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
    };
    fetch(ip, requestOptions).then(
      (response) => {
        if (response.status < 500) {
          callback(entity, "responded");
        } else {
          callback(entity, "timeout");
        }
      },
      (error) => {
        // console.log(error);
        callback(entity, "timeout");
      }
    );
  },
};

export { ping };

// // (A) LOCK SCREEN ORIENTATION
// export function lock_screen (orientation) {
//     // (A1) GO INTO FULL SCREEN FIRST
//     let de = document.documentElement;
//     if (de.requestFullscreen) { de.requestFullscreen(); }
//     else if (de.mozRequestFullScreen) { de.mozRequestFullScreen(); }
//     else if (de.webkitRequestFullscreen) { de.webkitRequestFullscreen(); }
//     else if (de.msRequestFullscreen) { de.msRequestFullscreen(); }

//     // (A2) THEN LOCK ORIENTATION
//     screen.orientation.lock(orientation);
// }

// // (B) UNLOCK SCREEN ORIENTATION
// export function unlock_screen () {
//     // (B1) UNLOCK FIRST
//     screen.orientation.unlock();

//     // (B2) THEN EXIT FULL SCREEN
//     if (document.exitFullscreen) { document.exitFullscreen(); }
//     else if (document.webkitExitFullscreen) { document.webkitExitFullscreen(); }
//     else if (document.mozCancelFullScreen) { document.mozCancelFullScreen(); }
//     else if (document.msExitFullscreen) { document.msExitFullscreen(); }
// }

/*** get server time for timer clock */
let triesServerTime = 0;

const getServerTime = async () => {
  const inicio = Date.now();
  let serverTime = Date.now();

  let serverApi = "http://localhost/api/time/";

  serverApi = sources[site].url + "/api/time/";

  if (document.location.origin == "http://localhost") {
    serverApi = "http://51.210.38.96/api/time/";
  }

  //serverApi = sources[site].url + "/api/time/";

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 2000);
  //var headers = apiRequestHeaders(null, true);
  // headers.append("mode", "cors");
  const optionsFetch = {
    method: "GET",
    signal: controller.signal,
    // headers: headers,
  };

  await fetch(serverApi, optionsFetch)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      //console.log(data.status);
      if (data.status === "Success") {
        const fin = Date.now();
        const suma =
          fin - inicio < 1000
            ? (fin - inicio) / 2
            : Math.floor((fin - inicio) / 1000) * 1000 +
              Math.floor((fin - inicio) % 1000) / 2;

        serverTime = Date.parse(data.time) + suma;
      } else {
        serverTime = Date.now();
      }
    })
    .catch(async (e) => {
      triesServerTime++;
      if (triesServerTime < 5) {
        serverTime = await getServerTime();
      } else {
        serverTime = Date.now();
      }

      //
    });
  clearTimeout(id);
  return Math.ceil(serverTime / 1000) * 1000;
};

export { getServerTime };

const setGlobalTime = async () => {
  const serverTime = await getServerTime();
  return { serverTime: serverTime, tries: triesServerTime };
};

export { setGlobalTime };

const getNow = (servertime, localtime, origin = "") => {
  //servertime and localtime are context vars in react components

  // console.log("services");
  // console.log("origen: ", origin);
  // console.log("get now", servertime, localtime);
  // console.log("date_now", Date.now());
  // console.log("'resta'", Date.now() - localtime);
  // console.log("---------------------------------");
  if (localtime > Date.now()) {
    //   window.location.reload();
  } else {
    return servertime + Date.now() - localtime;
  }
};
export { getNow };

export function getCleanPhoneNumber(phone) {
  let area_code;
  let restR;
  let arr = phone.split("-");
  let area_codeNR = arr[0];
  let restNR = arr[1];
  let restHF = restNR.split("");
  let area_codeHF = area_codeNR.split("");

  if (area_codeHF[0] == "0"){
    area_codeHF.shift();
    area_code = area_codeHF.join("");
  } else {
    area_code = area_codeHF.join("");
  }

  let phonelength = area_code + restHF

  if(phonelength.length == 12){
    restR = restHF.split("")
    restR.shift();
    restR.shift();
    restR = restR.join("");
  } else{
    restR = restHF.join("");
  }
  phone = area_code + restR;
  return phone;
}

export function copyLinkToClipBoard(url){
  navigator.clipboard.writeText(url);
  let e = document.getElementById("copy-link");
  e.classList.toggle("btn-default");
  e.classList.toggle("btn-success");
}

export function cuitVerificator(cuit, gender){
  let dni = cuit.toString().slice(2,10);
  let prefix  = cuit.toString().slice(0,2);

  let genderNumber = prefix;

  const generateDigitVerficator = () => {
    const multipliers = [5,4,3,2,7,6,5,4,3,2];
    const genderAndDni = `${genderNumber}${dni}`;

    let total = 0;
    for (let i=0; i < 10; i++) {
      let sum = +genderAndDni[i] * multipliers[i];
      total += sum;
    }
    const digitVerificator = 11 - (total % 11)

    if(digitVerificator === 10){
      genderNumber = 23;
      return generateDigitVerficator();
    }
    if(digitVerificator === 11) return 0;
    return digitVerificator;
  };

  const digitVerificator = generateDigitVerficator();
  let cuit_generated = `${genderNumber}${dni}${digitVerificator}`
  return cuit == cuit_generated;
}